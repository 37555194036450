import Footer from "./Components/Footer";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from "axios";

function Event(props) {

    let { id } = useParams();

    useEffect(() => {
        if (!props.event) props.getEvent(id);
    }, []);

    let _boletos = [];
    let _actividades = [];
    let _patrocinadores = [];

    if(props?.event){
        props?.event?.boletos?.forEach((boleto, i) => {
            const item = props?.cart?.items?.find( i => i.id === boleto?.id);
            _boletos.push(
                <tr key={"boleto_"+i}>
                    <th scope="row" colSpan={4}>{boleto?.name}</th>
                    <td colSpan={4}>${boleto?.precio}.00</td>
                    <td colSpan={4}>$25.00</td>
                    <td colSpan={4}>${(item?.cantidad ?? 0) * (boleto?.precio + 25)}.00</td>
                    <td>
                        <div className="row mx-auto">
                            <div className="col-4 border border-1" style={{cursor: "pointer"}} onClick={ (e) => props.updateCart(boleto.id, "-") }>-</div>
                            <div className="col-4 border border-1">{item?.cantidad ?? 0}</div>
                            <div className="col-4 border border-1" style={{cursor: "pointer"}} onClick={ (e) => props.updateCart(boleto.id, "+") }>+</div>
                        </div>
                    </td>
                </tr>
            )
        });

        props?.event?.itinerario?.forEach((actividad, i) => {
            _actividades.push(
                <div className="row py-3" key={"actividad_"+i}>
                    <div className="col-4 fw-semibold">{actividad.inicio} - {actividad.final}</div>
                    <div className="col-8 text-start">
                        <span className="fw-semibold">{actividad.name}</span><br/>
                        <small className="text-secondary">{actividad.descripcion}</small>
                    </div>
                </div>
            );
        })

        props?.event?.patrocinadores?.forEach((patrocinador, i) => {
            _patrocinadores.push(
                <div className="col" key={"patrocinador_"+i}>
                    <img src={""} className="img-fluid rounded-circle mx-auto w-100" style={{ height:"100px", width:"100px", backgroundColor: "#EBEF8A" }} alt={patrocinador.name} />
                </div>
            )
        })
    }

    console.log("Event", props.event);
    

    return (
        <div className="mx-auto">
            <div className="section text-center">
                <img src={"/assets/img/events/"+props?.event?.slug+"/logo.png"} className="img-fluid rounded-circle mx-auto" style={{ width: "100px", height: "100px", backgroundColor: "#EBEF8A" }} alt={""} />
                <h3 className="mt-2">{props?.event?.name}</h3>
            </div>
            <div className="mt-5" style={{ backgroundImage: "url('/assets/img/events/"+props?.event?.slug+"/banner.png')", backgroundColor: "#EBEF8A", height: "400px", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center" }}>
            </div>
            <div className="section mt-5 pt-5">
                <div className="container">
                    <div className="row mx-auto">
                        <div className="col-12 col-md-6 pt-5">
                            <img src={"/assets/img/events/"+props?.event?.slug+"/image.png"} alt={""} style={{ backgroundColor: "#EBEF8A", height: "300px", width: "300px" }} />
                            <br/>
                            {props?.event?.redes?.facebook && <a href={props?.event?.redes?.facebook}><img className="img-fluid m-3" style={{width: "35px"}} src="/assets/img/fb.webp"/></a>}
                            {props?.event?.redes?.instagram && <a href={props?.event?.redes?.instagram}><img className="img-fluid m-3" style={{width: "35px"}} src="/assets/img/ig.png"/></a>}
                            {props?.event?.redes?.tiktok && <a href={props?.event?.redes?.tiktok}><img className="img-fluid m-3" style={{width: "35px"}} src="/assets/img/tt.svg"/></a>}
                        </div>
                        <div className="col-12 col-md-6 pt-5 text-start">
                            <h4>Acerca de</h4>
                            <p>{props?.event?.descripcion}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="section mt-5 pt-5">
                <h4>Rutas</h4>
                <div className="mt-5" style={{ backgroundColor: "#EBEF8A", height: "400px" }}>
                </div>
            </div>*/}
            <div className="section mt-5 pt-5">
                <h4>Tickets</h4>
                <div className="mt-5">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col" colSpan={4}>Ticket</th>
                                <th scope="col" colSpan={4}>Precio</th>
                                <th scope="col" colSpan={4}>Costo de servicio</th>
                                <th scope="col" colSpan={4}>Total</th>
                                <th scope="col">Unidades</th>
                            </tr>
                        </thead>
                        <tbody>
                            {_boletos}
                        </tbody>
                    </table>
                </div>
                <div>
                    <Link to="cart" className="btn btn-primary">
                        Ir al carrito &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg>
                    </Link>
                </div>
            </div>
            {_actividades.length > 0 &&
                <div className="section mt-5 pt-5">
                    <h4>Itinerario</h4>
                    <p className="mt-3 fw-semibold">{props?.event?.fecha}</p>
                    <div className="container">
                        {_actividades}
                    </div>
                </div>
            }
            {_patrocinadores.length > 0 &&
                <div className="section mt-5 pt-5">
                    <h4>Sponsors</h4>
                    <div className="container py-5">
                        <div className="row">
                            {_patrocinadores}
                        </div>
                    </div>
                </div>
            }
            <Footer theme={"gray"}/>
        </div>
    );
}

export default Event;
