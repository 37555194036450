import Footer from "./Components/Footer";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from 'react';

function Compra(props) {

    let { id } = useParams();

    useEffect(() => {
        if (!props.compra) props.getCompra(id);
    }, []);

    let _pases = [];
    props?.compra?.pases?.forEach(pase => {
        _pases.push(
            <div className="col-md-3 col-6">
                <div className="card">
                    <div className="card-body text-start">
                        <h5 className="card-title">{pase["billet_name"]}</h5>
                        <p className="card-text">{pase["billet_correoelectronico"]}</p>
                        <p className="card-text">{pase["billet_telefono"]}</p><br/>
                        <p className="card-text">Pase adquirido: {pase["_billet_boleto_value@OData.Community.Display.V1.FormattedValue"]}</p>
                    </div>
                </div>
            </div>
        )
    });

    return (
        <div className="text-center">
            {props.compra && (
                <div className="container mb-5">
                    <div className="section text-center">
                        <img src={"/assets/img/events/"+props?.compra?.evento["billet_slug"]+"/logo.png"} className="img-fluid rounded-circle mx-auto" style={{ width: "100px", height: "100px", backgroundColor: "#EBEF8A" }} alt={""} />
                        <h1 className="mt-2">{props?.compra?.evento["billet_name"]}</h1>
                    </div>
                    <h3 className="mb-5 mt-3">Gracias por tu compra!</h3>
                    <p>Nos vemos el día: {props?.compra?.evento["billet_fecha@OData.Community.Display.V1.FormattedValue"]}</p>
                    <p className="my-5">Conserva este código: <strong>{id}</strong></p>
                    <h5>Tus pases: </h5>
                    <div className="row mb-5">
                        {_pases}
                    </div>
                    <small className="mt-5">Para cualquier aclaración envíanos un correo a <a href="mailto:mzavala@wabiit.com">mzavala@wabiit.com</a></small>
                </div>
            )}
            <Footer theme={"gray"} />
        </div>
    )

}

export default Compra;