function Footer(props) {
    let backgroundColor = "#1705F3";
    let src = "logo1.jpg";
    switch(props.theme){
        case "blue":
            backgroundColor = "#1705F3";
            src = "logo1.jpg"; 
            break;
        case "gray":
            backgroundColor = "#DADADA";
            src = "logo2.jpg";
            break;
    }
    return (
        <div className="mx-auto">
            <div style={{backgroundColor, height: "100px"}}>
                <img className="img-fluid" style={{maxHeight: "100px"}} src={"/assets/img/"+src} />
            </div>
            {/*<div style={{backgroundColor: "#DADADA", height: "100px"}}>
                <img className="img-fluid" style={{maxHeight: "100px"}} src={"assets/img/logo2.jpg"} />
            </div>*/}
        </div>
    );
}

export default Footer;