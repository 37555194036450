import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react'
import { useState } from 'react';
import Footer from "./Components/Footer";
import { Link } from "react-router-dom";
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

function Cart(props) {
    const [forms, setForms] = useState(createForms());
    
    initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY);

    let _forms = [];
    let _rows = [];

    let servicios = 0;
    let total = 0;

    function createForms() {
        let f = []
        props?.cart?.items?.forEach(i => {
            for (let j = 0; j < i?.cantidad; j++) {
                f.push({
                    id: i.id + "_" + j,
                    boleto: i.id,
                    nombre: "",
                    apellido_paterno: "",
                    apellido_materno: "",
                    domicilio: "",
                    ciudad: "",
                    estado: "",
                    correo: "",
                    telefono: "",
                    genero: "",
                    nombre_contacto1: "",
                    correo_contacto1: "",
                    telefono_contacto1: "",
                    nombre_contacto2: "",
                    correo_contacto2: "",
                    telefono_contacto2: "",
                    seguro: "",
                    padecimientos: "",
                    mascota: "",
                    acuerdo: false
                });
            }
        });
        return f;
    }
    
    props?.cart?.items?.forEach(i => {
        const boleto = props?.event?.boletos?.find(b => b.id === i.id); 

        _rows?.push(
            <tr style={{ borderBottom: "1px solid #AAAAAA" }}>
                <td colSpan={1} className="p-2">{boleto.name} x{i.cantidad}</td>
                <td colSpan={1} className="p-2">${boleto.precio * i.cantidad}.00</td>
                {/*<td colSpan={1} className="p-2"><small className="text-danger">eliminar</small></td>*/}
            </tr>
        );

        servicios += i.cantidad;
        total += (boleto.precio + 25) * i.cantidad;

        for (let j = 0; j < i?.cantidad; j++) {
            let found = forms?.find( f => f.id === i.id + "_" + j );
            let valido = validar(found);
            
            _forms.push(
                <div class="accordion-item">
                    <h2 class={"accordion-header border" + (valido ? "text-success-emphasis bg-success-subtle border-success-subtle" : "text-danger-emphasis bg-danger-subtle border-danger-subtle")}>
                        <button class="accordion-button bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + i.id + "_" + j} aria-expanded="false" aria-controls={"collapse_" + i.id + "_" + j}>
                            {boleto?.name} - {j + 1} &nbsp;&nbsp; { valido ? <span className="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                            </svg></span> : <span className='text-danger'>(Incompleto)</span>}
                        </button>
                    </h2>
                    <div id={"collapse_" + i.id + "_" + j} class="accordion-collapse collapse" data-bs-parent="#accordionBoletos">
                        <div class="accordion-body">
                            <h4>Datos de participante</h4>
                            <div className="mb-3 px-2">
                                <input name="nombre" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Nombre" type="text" />
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="apellido_paterno" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Apellido paterno" type="text" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="apellido_materno" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Apellido materno" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 px-2">
                                <input name="domicilio" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Domicilio" type="text" />
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="ciudad" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Ciudad" type="text" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="estado" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Estado" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="correo" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Correo eléctronico" type="text" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="telefono" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Celular" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <select name="genero" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100">
                                            <option selected disabled>Género</option>
                                            <option value="1">Masculino</option>
                                            <option value="2">Femenino</option>
                                            <option value="0">Prefiero no decirlo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="mb-3 px-2">
                                <input name="nombre_contacto1" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Contacto de emergencia 1" type="text" />
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="correo_contacto1" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Corre electrónico" type="text" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="telefono_contacto1" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Celular" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 px-2">
                                <input name="nombre_contacto2" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Contacto de emergencia 2" type="text" />
                            </div>
                            <div className="row mx-auto">
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="correo_contacto2" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Corre electrónico" type="text" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <input name="telefono_contacto2" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control w-100" placeholder="Celular" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 px-2">
                                <input name="seguro" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Póliza de Seguro" type="text" />
                            </div>
                            <div className="mb-3 px-2">
                                <input name="padecimientos" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Alergias o padecimientos" type="text" />
                            </div>
                            <hr />
                            {/*<div className="mb-3 px-2">
                                <input name="mascota" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-control fw-100" placeholder="Nombre de tu mascota" type="text" />
                            </div>*/}
                            <div className="mb-3 px-2">
                                <input name="acuerdo" onChange={e => handleChange(e, boleto.id + "_" + j)} className="form-check-input me-2" type="checkbox" />
                                <label>Estoy de acuerdo con la carta responsiva <a href={"/assets/responsivas/"+props?.event?.slug+"/Responsiva.pdf"}>VER CARTA RESPONSIVA</a></label>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    });

    function validar(formulario) {
        let final = !(
            formulario.nombre === "" ||
            formulario.apellido_paterno === "" ||
            formulario.apellido_materno === "" ||
            formulario.domicilio === "" ||
            formulario.ciudad === "" ||
            formulario.estado === "" ||
            formulario.correo === "" ||
            formulario.telefono === "" ||
            formulario.genero === "" ||
            formulario.nombre_contacto1 === "" ||
            formulario.correo_contacto1 === "" ||
            formulario.telefono_contacto1 === "" ||
            formulario.nombre_contacto2 === "" ||
            formulario.correo_contacto2 === "" ||
            formulario.telefono_contacto2 === "" ||
            formulario.seguro === "" ||
            formulario.padecimientos === "" ||
            //formulario.mascota === "" ||
            formulario.acuerdo === false
        );
        return (final);
    }

    function handleChange(event, id){
        let aux_forms = [...forms];
        let aux_form = aux_forms.find(f => f.id === id);
        if(event.target.name !== "acuerdo") aux_form[event.target.name] = event.target.value;
        else aux_form.acuerdo = event.target.checked;
        setForms(aux_forms);
    }

    function validarTodo(){
        let valid = true;
        forms.forEach( f => {
            if(!validar(f)) valid = false;
        })
        return valid;
    }
    
    const initialization = {
        amount: total,
    };
    
    const onSubmit = async (formData) => {
        formData.pases = forms;
        formData.eventid = props.event.id;
        formData.amount = total;
        axios.post("http://localhost:3001/api/pay", formData).then((response) => {
            if(response.data.status === "error"){
                NotificationManager.error(response.data.data, 'Error', 5000, () => {
                    alert('callback');
                });
            }
            else{
                NotificationManager.success('En breve recibirás un correo con tus pases', 'Compra exitosa');
                setTimeout(() => {
                    window.location = "/compra/"+response.data.data.billet_compraid
                }, 3000);
            }
            
        }).catch((error) => {
            console.error(error);
            NotificationManager.error(error?.message, 'Error', 5000, () => {
                alert('callback');
            });
        });
    };

    const onError = async (error) => {
        console.error(error);
    };

    const onReady = async () => {
        console.log("Ready");
    };
    
    return (
        <div className="mx-auto">
            <NotificationContainer/>
            <div className="section text-center">
                <img src={"/assets/img/events/"+props?.event?.slug+"/logo.png"} className="img-fluid rounded-circle mx-auto" style={{ width: "100px", height: "100px", backgroundColor: "#EBEF8A" }} alt={""} />
                <h3 className="mt-2">{props?.event?.name}</h3>
            </div>
            <div className="mt-5" style={{ backgroundImage: "url('/assets/img/events/"+props?.event?.slug+"/banner.png')", backgroundColor: "#EBEF8A", height: "400px", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center" }}>
            </div>
            <div className="section">
                <div className="container text-start py-5 ps-4">
                    <Link className="text-secondary" to={"/event/" + props?.event?.slug}>VOLVER A VISTA GENERAL</Link>
                </div>
            </div>
            <div className="section container">
                <div className="row mx-auto">
                    <div className="col-12 col-md-7 text-start">
                        <div class="accordion" id="accordionBoletos">
                            {_forms}
                        </div>
                    </div>
                    <div className="col-12 col-md-5 text-start">
                        <h4>Carrito</h4>
                        <table className="table text-start w-100">
                            <tr>
                                <th colSpan={1} className="text-secondary p-2">Item</th>
                                <th colSpan={1} className="text-secondary p-2">Precio</th>
                                <th colSpan={1}></th>
                            </tr>
                            {_rows}
                            <tr>
                                <td colSpan={1} className="p-2">Costo de servicio x{servicios}</td>
                                <td colSpan={1} className="p-2">${servicios * 25}.00</td>
                                <td colSpan={1} className="p-2"></td>
                            </tr>
                        </table>
                        <h4 className="text-end">Total: ${total}.00</h4>
                        { validarTodo() &&
                        <CardPayment className="mt-5"
                            initialization={initialization}
                            onSubmit={onSubmit}
                            onReady={onReady}
                            onError={onError}
                        />}
                    </div>
                </div>
            </div>
            <Footer theme={"gray"} />
        </div>
    );
}

export default Cart;
