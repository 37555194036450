import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Event from './Event';
import Cart from './Cart';
import Compra from './Compra';
import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [ event, setEvent ] = useState(null);
  const [ cart, setCart] = useState({event: null, items: []});
  const [ compra, setCompra] = useState(null);

  console.log("Env", process.env);

  function getEvent(slug){
    console.log("Get Event", process.env.REACT_APP_API_URL);
    
    axios.post(process.env.REACT_APP_API_URL, { slug }).then( response => {
      setEvent(response.data);
      setCart({event: response.data.id, items: []});
    }).catch(error => {
      console.log("Error", error);
      
      console.error(error)
  });
  }

  function getCompra(id){
    axios.post(process.env.REACT_APP_API_URL + "/compra", { compra: id }).then( response => {
      if(response.data.status === "success") setCompra(response.data.data);
      else window.location = "/";
    }).catch(error => console.error(error));
  }

  function updateCart (boleto_id, accion) {
    let items = [...cart?.items]
    let item = items?.find( i => i.id === boleto_id );

    if(!item){
      items.push({
        id: boleto_id,
        cantidad: 1
      })
    }
    else{
      if(accion === "+") item.cantidad ++;
      else if (item.cantidad > 0) item.cantidad --;
    }

    setCart({ event: cart?.event, items })
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/event/:id" element={<Event event={event} cart={cart} updateCart={updateCart} getEvent={getEvent}/>} />
          <Route path="/event/:id/cart" element={<Cart event={event} cart={cart} />} />
          <Route path="/compra/:id" element={<Compra compra={compra} getCompra={getCompra} />} />
          <Route path="*" element={<Navigate to="/event/carrera-morelos" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
